export default function () {
  return [
    {
      action: "Согласовано ГИП",
      position: "ГИП",
      name: "Павлов И.В.",
      signature: "pending",
      date: "15.02.2021",
    },
    {
      action: "Задание выдал",
      position: "Гл. спец",
      name: "Рахиева Д.Т",
      signature: "signed",
      date: "15.02.2021",
    },
    {
      action: "Задание выдал",
      position: "Гл. спец",
      name: "Кадыров С.А.",
      signature: "declined",
      date: "15.02.2021",
    },
    {
      action: "Задание принял",
      position: "Гл. спец",
      name: "Романов Д.В.",
      signature: "signed",
      date: "15.02.2021",
    },
  ];
}
